import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/noahgilmore.com/noahgilmore.com/src/components/BlogPageLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <blockquote>
      <p parentName="blockquote">{`Update (4/27/2020): 4 years after writing this article, I realized that it could be improved on a little bit. Added info about the recommended way to override equality for NSObjects, and a note about how Swiftlint has a rule to handle this.`}</p>
    </blockquote>
    <p>{`Swift can be tricky sometimes. For example, what does the following print?`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`A`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: NSObject {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` x: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = x`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`==`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`left`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: A, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`right`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: A) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Bool`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` left.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` == right.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`x`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`A`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) == `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`A`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`))`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`([`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`A`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)] == [`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`A`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)])`}</span></span></code></pre>
    <p>{`Perhaps surprisingly, it's this:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "nohighlight"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`true`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`false // huh?!`}</span></code></pre>
    <h3 {...{
      "id": "equatable-conformance-is-hard"
    }}>{`Equatable conformance is hard`}</h3>
    <p>{`Why does comparing two arrays with equal contents return false? It turns out that:`}</p>
    <ul>
      <li parentName="ul">{`Array is bridged to NSArray`}</li>
      <li parentName="ul">{`NSArray doesn't declare a `}<inlineCode parentName="li">{`==(_:_:)`}</inlineCode>{` function, so it falls back to NSObject's `}<inlineCode parentName="li">{`==(_:_:)`}</inlineCode>{`, which calls `}<inlineCode parentName="li">{`isEqual`}</inlineCode></li>
      <li parentName="ul">{`NSArray's `}<inlineCode parentName="li">{`isEqual`}</inlineCode>{` `}<a parentName="li" {...{
          "href": "https://github.com/apple/swift-corelibs-foundation/blob/0cbb3225660752987a6c2cf672886fca434d3b1d/Foundation/NSArray.swift#L310"
        }}>{`compares objects by casting to AnyHashable`}</a></li>
    </ul>
    <p>{`We can see that this is the problem by casting the objects to `}<inlineCode parentName="p">{`AnyHashable`}</inlineCode>{` before comparing them:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`A`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) as `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`AnyHashable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` == `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`A`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) as `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`AnyHashable`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span></code></pre>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "nohighlight"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`false`}</span></code></pre>
    <p>{`Apparently, doing this will use the NSObject implementation of `}<inlineCode parentName="p">{`==(_:_:)`}</inlineCode>{` rather than the `}<inlineCode parentName="p">{`A`}</inlineCode>{` one. The NSObject implementation of `}<inlineCode parentName="p">{`==(_:_:)`}</inlineCode>{` checks isEqual, which returns false because the two elements aren't the same in memory. We can see this by adding an override:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`B`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: NSObject {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` x: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = x`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`isEqual`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`object`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Any`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Bool`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"isEqual for B"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`super`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`isEqual`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(object)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`==`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`left`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: B, `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`right`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: B) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Bool`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`"== for B"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` left.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` == right.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`x`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <p>{`Then,`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`([`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`B`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)] == [`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`B`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)])`}</span></span></code></pre>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "nohighlight"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`isEqual for B`}</span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`false`}</span></code></pre>
    <h3 {...{
      "id": "the-fix"
    }}>{`The fix`}</h3>
    <p>{`The best way to make an NSObject subclass use custom equality inside an array is to override isEqual:`}</p>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk4"
          }}>{`class`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`C`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: NSObject {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` x: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`init`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Int`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`) {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` = x`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`override`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`func`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`isEqual`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`_`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`object`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Any`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`?) -> `}</span><span parentName="span" {...{
            "className": "mtk10"
          }}>{`Bool`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`guard`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`let`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` object = object as? C `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`else`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`false`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk15"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` object.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` == `}</span><span parentName="span" {...{
            "className": "mtk4"
          }}>{`self`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`x`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`  }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`}`}</span></span></code></pre>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "swift"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk11"
          }}>{`print`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`([`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`C`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)] == [`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`C`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk11"
          }}>{`x`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`: `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`1`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)])`}</span></span></code></pre>
    <pre {...{
      "className": "dark-default-dark vscode-highlight",
      "data-language": "nohighlight"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}>{`true`}</span></code></pre>
    <p>{`Of course, if we defined `}<inlineCode parentName="p">{`A`}</inlineCode>{` as a `}<inlineCode parentName="p">{`struct`}</inlineCode>{` or a regular `}<inlineCode parentName="p">{`class`}</inlineCode>{` in the first place, there won't be `}<inlineCode parentName="p">{`==(_:_:)`}</inlineCode>{` defined for `}<inlineCode parentName="p">{`[A]`}</inlineCode>{`, which means the compiler would catch our mistake instead of falling back to something that might not work correctly.`}</p>
    <p>{`If you use Swiftlint, there's actually a rule you can use to prefer the recommended way of overriding `}<inlineCode parentName="p">{`isEqual`}</inlineCode>{`: `}<a parentName="p" {...{
        "href": "https://realm.github.io/SwiftLint/nsobject_prefer_isequal.html"
      }}>{`nsobject_prefer_isequal`}</a>{`. Highly recommended!`}</p>
    <p>{`A working playground with the code from this post can be found `}<a parentName="p" {...{
        "href": "https://github.com/noahsark769/code-examples/blob/ed9df41aae709eaeb4e334e3e9a5b1a650deef4d/swift/swift-nsobject-equatable.playground/Contents.swift"
      }}>{`here`}</a>{`.`}</p>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .dark-default-dark {
background-color: #1E1E1E;
color: #D4D4D4;
}

.dark-default-dark .mtk1 { color: #D4D4D4; }
.dark-default-dark .mtk2 { color: #1E1E1E; }
.dark-default-dark .mtk3 { color: #6A9955; }
.dark-default-dark .mtk4 { color: #569CD6; }
.dark-default-dark .mtk5 { color: #D16969; }
.dark-default-dark .mtk6 { color: #D7BA7D; }
.dark-default-dark .mtk7 { color: #B5CEA8; }
.dark-default-dark .mtk8 { color: #CE9178; }
.dark-default-dark .mtk9 { color: #646695; }
.dark-default-dark .mtk10 { color: #4EC9B0; }
.dark-default-dark .mtk11 { color: #DCDCAA; }
.dark-default-dark .mtk12 { color: #9CDCFE; }
.dark-default-dark .mtk13 { color: #000080; }
.dark-default-dark .mtk14 { color: #F44747; }
.dark-default-dark .mtk15 { color: #C586C0; }
.dark-default-dark .mtk16 { color: #6796E6; }
.dark-default-dark .mtk17 { color: #808080; }
.dark-default-dark .mtki { font-style: italic; }
.dark-default-dark .mtkb { font-weight: bold; }
.dark-default-dark .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      